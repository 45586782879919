import { on } from 'delegated-events';

on('click', '.js-share', async (event) => {
  const { currentTarget: $target } = event;

  event.preventDefault();

  if (!('share' in window.navigator)) {
    await import(
      /* webpackChunkName: "share-api-polyfill" */ 'share-api-polyfill'
    );
  }

  const {
    url = window.location.href,
    title = document.title,
    text = '',
  } = $target.dataset;

  await window.navigator.share(
    {
      title,
      text,
      url,
    },
    { language: 'de' },
  );
});
